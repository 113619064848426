import { classNames } from "primereact/utils"
import { IBaseUser } from "src/api/types/user"
import { roleValues } from "src/constants/role-values"
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { Badge } from 'primereact/badge';
import { IContractListItem } from "src/api/types/contracts";
import { ColumnBodyOptions } from "primereact/column";
import { ITransactionListItem } from "src/api/types/transactions";

export const fullNameBodyTemplate = (rowData: IBaseUser) => {
    return (<>{`${rowData.firstName} ${rowData.lastName}`}</>)
}

export const roleBodyTemplate = (rowData: IBaseUser) => {
    return (<>{roleValues[rowData.role]}</>)
}

export const emailVerifiedBodyTemplate = (rowData: IBaseUser) => {
    return (<span className="pl-2">
        <i className={classNames("pi", { "text-green-600 pi-check": rowData.emailVerified, "text-red-600 pi-times": !rowData.emailVerified })}></i>
    </span>)
}


export const userDetailsOfContract = (rowData: IContractListItem, options: ColumnBodyOptions) => {
    if (options.field === "freelancer") {
        return <Link to={ROUTE_CONSTANTS.DASHBOARD.FREELANCERS.DETAILS.GET_BY_DATA(rowData.freelancer.id).ABSOLUTE}>
            {
                `${rowData.freelancer.firstName} ${rowData.freelancer.lastName}`
            }
        </Link>
    }
    return <Link to={ROUTE_CONSTANTS.DASHBOARD.CUSTOMERS.DETAILS.GET_BY_DATA(rowData.customer.id).ABSOLUTE}>
        {
            `${rowData.customer.firstName} ${rowData.customer.lastName}`
        }
    </Link>
}

export const dateBodyTemlateOfContract = (rowData: Record<string,string>, options: ColumnBodyOptions)=>{
    return <>{dayjs(rowData[options.field]).format("YYYY-MM-DD HH:mm")}</>
}

export const emailBodyTemplate = (rowData: IBaseUser) => {

    const detectRouteBasedOnRole = () => {
        switch (rowData.role) {
            case "freelancer":
                return ROUTE_CONSTANTS.DASHBOARD.FREELANCERS.DETAILS.GET_BY_DATA(rowData.id).ABSOLUTE
            case "customer":
                return ROUTE_CONSTANTS.DASHBOARD.CUSTOMERS.DETAILS.GET_BY_DATA(rowData.id).ABSOLUTE
            case "admin":
                return ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATORS.DETAILS.GET_BY_DATA(rowData.id).ABSOLUTE
            case "financialManager":
                return ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATORS.DETAILS.GET_BY_DATA(rowData.id).ABSOLUTE
            case "systemManager":
                return ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATORS.DETAILS.GET_BY_DATA(rowData.id).ABSOLUTE
            case "superAdmin":
                return ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATORS.DETAILS.GET_BY_DATA(rowData.id).ABSOLUTE
            default:
                return ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATORS.DETAILS.GET_BY_DATA(rowData.id).ABSOLUTE;
        }
    }
    return (
        <Link
            to={detectRouteBasedOnRole()}
        >
            {rowData.email}
        </Link>
    )
}

export const dateCreatedAtBodyTemplate = (rowData: IBaseUser) => {
    return <>{dayjs(rowData.createdAt).format("YYYY-MM-DD HH:mm")}</>
}

export const fuckingDateTemplate = (rowData: {date:string}) => {
    console.log(rowData.date);
    return <>{dayjs(rowData.date).format("YYYY-MM-DD HH:mm")}</>
}


export const statusTemplate = (rowData: IBaseUser) => {
    return <Badge value={rowData.status} severity={rowData.status === "Active" ? "success" : "warning"} />
}

export const transactionAmount = (rowData: ITransactionListItem) => {
    return <span>{rowData.amount} KD</span>
}

export const transactionType = (rowData: ITransactionListItem) => {
    return <span>{rowData.type}</span>
}

export const transactionTitle = (rowData: ITransactionListItem) => {
    return <span>{rowData.title}</span>
}

export const transactionDate = (rowData: ITransactionListItem) => {
    return <>{dayjs(rowData.registeredAt).format("YYYY-MM-DD HH:mm")}</>
}

export const transactionContractId = (rowData: ITransactionListItem) => {
    return (
        <Link
            style={{textDecoration: 'none', color: 'var(--primary-blue-900)'}}
            to={ROUTE_CONSTANTS.DASHBOARD.CONTRACTS.DETAILS.GET_BY_DATA(+rowData.contractId).ABSOLUTE} 
        >
            {rowData.contractId}
        </Link>
    )
}