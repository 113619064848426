import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { useState } from "react"
import { IPaginatedRequestBody } from "src/api/types/axios-response";
import { IGetListBody } from "src/api/types/base-data";
import { getAxiosError } from "src/utils/get-axios-error";
import { responseFileDownloade } from "src/utils/response-file-downloade";
import { toast } from "src/utils/toast";
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone)

type TExportAPIFN = (data?: IPaginatedRequestBody | undefined)=> Promise<AxiosResponse<Blob, any>>;
type ListType = 'customers' | 'freelancers' | 'financial_transactions' | 'payments' | 'contracts';

export const useListCSVDownload = (exportAPI:TExportAPIFN,listType:ListType)=>{
    const [exportLoading,setExportLoading] = useState(false);

    const exportResult = async (data: IGetListBody) => {
        try {
            setExportLoading(true);
            const result = await exportAPI({
                ...data,
                clientTimeZone:dayjs.tz.guess()
            });
            responseFileDownloade(result.data, `${listType}_${dayjs().format('YYYY_MM_DD_HH_mm_ss')}.csv`);
        } catch (err) {
            const error = getAxiosError(err);
            toast.error(error.meta?.message!);
        } finally {
            setExportLoading(false);
        }
    }

    return {
        exportResult,
        exportLoading
    }
}