import { MenuItem } from "primereact/menuitem";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import SidebarMenuItem from "./MenuItem";

enum MainMenuLabels {
  USER_MANAGEMENT = "User Management",
  CONTRACT_MANAGEMENT = "Contract Management",
  CATEGORY_MANAGEMENT = "Category Management",
  FEE_MANAGEMENT = "Fee Management",
  FINANCIAL_MANAGEMENT = "Financial Management",
  SETTINGS = "Settings",
  PROMOTION = "Promotion",
  HOME = "Dynamic Home Page",
}

const SidebarMenuItems: MenuItem[] = [
  {
    label: MainMenuLabels.USER_MANAGEMENT,
    className: "g-sidebar-menu-item",
    items: [
      {
        template: (
          <SidebarMenuItem
            label="Freelancers"
            icon="ph-briefcase"
            url={ROUTE_CONSTANTS.DASHBOARD.FREELANCERS.ROOT.ABSOLUTE}
          />
        ),
      },
      {
        template: (
          <SidebarMenuItem
            label="Customers"
            icon="ph-users"
            url={ROUTE_CONSTANTS.DASHBOARD.CUSTOMERS.ROOT.ABSOLUTE}
          />
        ),
      },
      {
        template: (
          <SidebarMenuItem
            label="Administrators"
            icon="ph-user-gear"
            url={ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATORS.ROOT.ABSOLUTE}
          />
        ),
      },
    ],
  },
  {
    label: MainMenuLabels.CONTRACT_MANAGEMENT,
    className: "g-sidebar-menu-item",
    items: [
      {
        template: (
          <SidebarMenuItem
            label="Contracts"
            icon="ph-barcode"
            url={ROUTE_CONSTANTS.DASHBOARD.CONTRACTS.ROOT.ABSOLUTE}
          />
        ),
      },
    ],
  },
  {
    label: MainMenuLabels.CATEGORY_MANAGEMENT,
    className: "g-sidebar-menu-item",
    items: [
      {
        template: (
          <SidebarMenuItem
            label="Categories"
            icon="ph-rows"
            url={ROUTE_CONSTANTS.DASHBOARD.CATEGORIES.ROOT.ABSOLUTE}
          />
        ),
      },
    ],
  },
  {
    label: MainMenuLabels.FEE_MANAGEMENT,
    className: "g-sidebar-menu-item",
    items: [
      {
        template: (
          <SidebarMenuItem
            label="Fee"
            icon="ph-coins"
            url={ROUTE_CONSTANTS.DASHBOARD.FEE.ROOT.ABSOLUTE}
          />
        ),
      },
    ],
  },
  {
    label: MainMenuLabels.FINANCIAL_MANAGEMENT,
    className: "g-sidebar-menu-item",
    items: [
      {
        template: (
          <SidebarMenuItem
            label="Contract Transactions"
            icon="ph-coins"
            url={ROUTE_CONSTANTS.DASHBOARD.FINANCIAL.TRANSACTIONS.ABSOLUTE}
          />
        ),
      },
      {
        template: (
          <SidebarMenuItem
            label="Contract Payments"
            icon="ph-cardholder"
            url={ROUTE_CONSTANTS.DASHBOARD.FINANCIAL.PAYMENTS.ABSOLUTE}
          />
        ),
      },
    ],
  },
  {
    label: MainMenuLabels.SETTINGS,
    className: "g-sidebar-menu-item",
    items: [
      {
        template: (
          <SidebarMenuItem
            label="Global Settings"
            icon="ph-faders"
            url={ROUTE_CONSTANTS.DASHBOARD.SETTINGS.GLOBAL.ABSOLUTE}
          />
        ),
      },
      {
        template: (
          <SidebarMenuItem
            label="Static Pages"
            icon="ph-faders"
            url={ROUTE_CONSTANTS.DASHBOARD.SETTINGS.STATIC_PAGES.ABSOLUTE}
          />
        ),
      },
    ],
  },
  {
    label: MainMenuLabels.PROMOTION,
    className: "g-sidebar-menu-item",
    items: [
      {
        template: (
          <SidebarMenuItem
            label="Coupon Code"
            icon="ph-ticket"
            url={ROUTE_CONSTANTS.DASHBOARD.PROMOTION.COUPON.ROOT.ABSOLUTE}
          />
        ),
      },
    ],
  },
  {
    label: MainMenuLabels.HOME,
    className: "g-sidebar-menu-item",
    items: [
      {
        template: (
          <SidebarMenuItem
            label="Relocation/Count"
            icon="pi pi-arrows-v"
            url={ROUTE_CONSTANTS.DASHBOARD.HOME.RELOCATION.ROOT.ABSOLUTE}
          />
        ),
      },
      {
        template: (
          <SidebarMenuItem
            label="Customize/Arrange"
            icon="pi pi-star-fill"
            url={
              ROUTE_CONSTANTS.DASHBOARD.HOME.RELOCATION.TO_SHOW_ITEMS.ABSOLUTE
            }
          />
        ),
      },
    ],
  },
];

const financialManagerMainMenuLabelsWhiteList = [
  MainMenuLabels.CONTRACT_MANAGEMENT,
  MainMenuLabels.FEE_MANAGEMENT,
  MainMenuLabels.FINANCIAL_MANAGEMENT,
];
export const FilteredSidebarMenuItems = (userRole: string) => {
  if (userRole === "financialManager") {
    return SidebarMenuItems.filter((mainMenuItem) =>
      financialManagerMainMenuLabelsWhiteList.includes(
        mainMenuItem.label as MainMenuLabels,
      ),
    );
  }
  return SidebarMenuItems;
};
